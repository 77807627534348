import * as React from "react";
import { Card, CardContent, Hero, Button, Icon } from "@literati/dj-react";

import useBoxReturn from "boxReturn/useBoxReturn";
import { ReturnedBook as ReturnedBookType } from "boxReturn/useBoxReturn/types";
import ToteBooksButton from "./ToteBooksButton";
import LoadingIndicator from "./LoadingIndicator";
import useHandleBookScan from "./useHandleBookScan";
import AddBookButton from "./AddBookButton";

/**
 * A view for box level information and actions
 */
export default function BoxView() {
  useHandleBookScan();

  return (
    <>
      <ViewHero />
      <div className="container">
        <div className="side-area">
          <h2>Box Issues</h2>
          <IsReturnToSenderButton />
          <IsEmptyButton />
          <IsUnsealedButton />
          <CustomerRequestedCall />
          <CustomerRequestedCancel />
          <IntakeNotes />
        </div>
        <div className="main-area">
          <BookList />
          <LoadingIndicator />
        </div>
      </div>
    </>
  );
}

function ViewHero() {
  const { boxShipmentId, boxReturn } = useBoxReturn();
  return (
    <Hero>
      <div className="hero-split">
        <div>
          <h1>
            Box #{boxShipmentId}{" "}
            <small>{boxReturn?.boxShipment.personalizationName}</small>
          </h1>
          <p>
            Scan a book to add it to the return. Scan the box to complete the
            return.
          </p>
        </div>
        <div className="hero-actions">
          <ToteBooksButton />
        </div>
      </div>
    </Hero>
  );
}

interface ToggleButtonProps {
  text: string;
  isActive: boolean;
  setActive: (isActive: boolean) => void;
}
function ToggleButton(props: ToggleButtonProps) {
  const { text, isActive, setActive } = props;

  return (
    <>
      <Button
        className="side-button"
        variant={isActive ? "success" : "default"}
        onClick={() => setActive(!isActive)}
      >
        <Icon name={isActive ? "check-circle" : "circle"} />
        {text}
      </Button>
    </>
  );
}

function IsReturnToSenderButton() {
  const { isReturnToSender, setIsReturnToSender } = useBoxReturn();

  return (
    <ToggleButton
      text="Return To Sender"
      isActive={isReturnToSender}
      setActive={setIsReturnToSender}
    />
  );
}

function IsEmptyButton() {
  const { isEmpty, setIsEmpty } = useBoxReturn();

  return (
    <ToggleButton text="Empty" isActive={isEmpty} setActive={setIsEmpty} />
  );
}

function IsUnsealedButton() {
  const { isUnsealed, setIsUnsealed } = useBoxReturn();

  return (
    <ToggleButton
      text="Unsealed"
      isActive={isUnsealed}
      setActive={setIsUnsealed}
    />
  );
}

function CustomerRequestedCall() {
  const { customerRequestedCall, setCustomerRequestedCall } = useBoxReturn();

  return (
    <ToggleButton
      text="Phone Call Requested"
      isActive={customerRequestedCall}
      setActive={setCustomerRequestedCall}
    />
  );
}

function CustomerRequestedCancel() {
  const { customerRequestedCancel, setCustomerRequestedCancel } =
    useBoxReturn();

  return (
    <ToggleButton
      text="Cancellation Requested"
      isActive={customerRequestedCancel}
      setActive={setCustomerRequestedCancel}
    />
  );
}

/**
 * An input for recording notes indented for CS to review
 * before approving the return.
 */
function IntakeNotes() {
  const { intakeNotes, setNotes } = useBoxReturn();

  function handleChange(event) {
    setNotes(event.target.value);
  }
  return (
    <>
      <textarea
        value={intakeNotes}
        onChange={handleChange}
        placeholder="Other intake issues for CS to address"
      />
    </>
  );
}

/**
 * Displays notes set by a CS agent that might
 * be useful for the intake user.
 */
function SpecialReturnsNotes() {
  const { boxReturn } = useBoxReturn();

  if (!boxReturn?.specialReturnProcessing) return null;

  return (
    <>
      <h2>Notes From CS</h2>
      <Card className="side-card">
        <CardContent>{boxReturn.specialReturnProcessing}</CardContent>
      </Card>
    </>
  );
}

/**
 * A list of books that have been scanned from the returned box
 */
function BookList() {
  const { returnedBooks } = useBoxReturn();

  return (
    <>
      <div className="flex-row">
        <h2>Books</h2>
        <AddBookButton />
      </div>
      <table>
        <thead>
          <tr>
            <th>Book</th>
            <th>Tote Type</th>
            <th>Damages</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {returnedBooks.map((rb) => (
            <ReturnedBook key={rb.id} returnedBook={rb} />
          ))}
        </tbody>
      </table>
    </>
  );
}

type ReturnedBookProps = {
  returnedBook: ReturnedBookType;
};

function ReturnedBook(props: ReturnedBookProps) {
  const { returnedBook } = props;
  const { book, toteName, damages, barcode } = returnedBook;
  const {
    selectReturnedBook,
    damageCategories,
    damageSeverities,
    removeReturnedBook,
  } = useBoxReturn();

  function removeBook() {
    if (!window.confirm("Are you sure")) return;
    removeReturnedBook(returnedBook.id);
  }

  return (
    <tr>
      <td onClick={() => selectReturnedBook(returnedBook.id)}>
        <div>{book ? book.title : "Unknown"}</div>
        <div>{barcode}</div>
      </td>
      <td>{toteName}</td>
      <td>
        {damages.length
          ? damages.map((damage) => (
              <div key={damage.category}>
                {damageCategories[damage.category]} (
                {damageSeverities[damage.severity]})
              </div>
            ))
          : "None"}
      </td>
      <td>
        <Button size="sm" variant="danger" onClick={removeBook}>
          Remove
        </Button>
      </td>
    </tr>
  );
}
