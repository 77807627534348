export enum ReducerType {
  setErrors,
  setCurrentBook,
  updateLoading,
  setBoxReturn,
  updateReturnedBook,
  setDamageOptions,
  setNotes,
  setDamageCategory,
  setShowTotes,
  goBack,
  removeBook,
  setIsReturnToSender,
  setIsEmpty,
  setIsUnsealed,
  setCustomerRequestedCall,
  setCustomerRequestedCancel,
}

export interface Methods {
  onError(error: unknown): void;
  setErrors(errors: string[]): void;
  checkForErrors(res: object): boolean;
  closeView(): void;
  reverseBoxReturn(): void;
  createReturnedBook(barcode: string): Promise<object>;
  toggleDamage(severity: string, notes?: string): Promise<object>;
  getDamageOptions(): Promise<object>;
  closeBoxReturn(): Promise<object>;
  selectBook(barcode: string): void;
  goBack(): void;
  setNotes(notes: string): void;
  setDamageCategory(category: string): void;
  selectReturnedBook(returnedBookId: string): void;
  setShowTotes(showTotes: boolean): void;
  removeReturnedBook(returnedBookId: string): void;
  setIsEmpty(isEmpty: boolean): void;
  setIsUnsealed(isUnsealed: boolean): void;
  setIsReturnToSender(isReturnToSender: boolean): void;
  setCustomerRequestedCall(isSet: boolean): void;
  setCustomerRequestedCancel(isSet: boolean): void;
}

export interface SetBoxReturnPayload {
  boxReturn: BoxReturn & {
    intakeNotes: string;
    returnedbookSet: ReturnedBook[];
    isEmpty: boolean;
    isUnsealed: boolean;
    isReturnToSender: boolean;
    customerRequestedCall: boolean;
    customerRequestedCancel: boolean;
  };
}

export interface RemoveBookPayload {
  returnedBookId: string;
}
export interface Book {
  id: number;
  title: string;
}

export interface ReturnedDamage {
  category: string;
  severity: string;
  notes: string;
}

export interface ReturnedBook {
  id: string;
  book?: Book;
  intakeType: string;
  toteName: string;
  damages: ReturnedDamage[];
  barcode: string;
}

export interface BoxShipment {
  personalizationName;
}
export interface BoxReturn {
  boxShipmentId: number;
  specialReturnProcessing: string;
  boxShipment?: BoxShipment;
  stage: string;
  intakeStationId: string;
}

export type DamageChoices = {
  [value: string]: string;
};
export interface State {
  errors: string[];
  boxShipmentId?: string;
  stationId: string;
  isLoading: boolean;
  loadingCount: number;
  boxReturn?: BoxReturn;
  returnedBooks: ReturnedBook[];
  currentReturnedBookId?: string;
  intakeNotes: string;
  damageCategories: DamageChoices;
  damageSeverities: DamageChoices;
  damageCategory?: string;
  showTotes: boolean;
  isReturnToSender: boolean;
  isEmpty: boolean;
  isUnsealed: boolean;
  customerRequestedCall: boolean;
  customerRequestedCancel: boolean;
}

export interface StateRef {
  current: State;
}
interface ReducerAction {
  type: ReducerType;
  payload?: object;
}

export type Dispatch = (a: ReducerAction) => void;
export type SetErrors = (a: string[]) => void;
export type CheckForErrors = (a: object) => boolean;

export interface UseMethodsParams {
  stateRef: StateRef;
  dispatch: Dispatch;
  setErrors: SetErrors;
}
export interface MethodHookParams {
  stateRef: StateRef;
  dispatch: Dispatch;
  checkForErrors: CheckForErrors;
  setErrors: SetErrors;
  methods: Methods;
}

export type MethodHook = (params: MethodHookParams) => void;

export type ContextValue = State &
  Methods & {
    state: State;
    stateRef: StateRef;
    dispatch: Dispatch;
    methods: Methods;
  };
